/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import t from "./ArrayPool.js";
import s from "./Evented.js";
import { makeHandle as i } from "./handleUtils.js";
import { clone as r } from "./lang.js";
import n from "./ObjectPool.js";
import { ObservableChangesType as h } from "./ObservableChangesType.js";
import { schedule as o } from "./scheduling.js";
import { ensureType as l, ensureOneOfType as a } from "./accessorSupport/ensureType.js";
import { trackAccess as c } from "./accessorSupport/tracking.js";
import { property as f } from "./accessorSupport/decorators/property.js";
import { shared as _ } from "./accessorSupport/decorators/shared.js";
import { subclass as m } from "./accessorSupport/decorators/subclass.js";
import { SimpleObservable as u } from "./accessorSupport/tracking/SimpleObservable.js";
var g;
class d {
  constructor() {
    this.target = null, this.cancellable = !1, this.defaultPrevented = !1, this.item = void 0, this.type = void 0;
  }
  preventDefault() {
    this.cancellable && (this.defaultPrevented = !0);
  }
  reset(e) {
    this.defaultPrevented = !1, this.item = e;
  }
}
class p {
  constructor(e, t, s, i, r) {
    this.target = e, this.added = t, this.removed = s, this.start = i, this.deleteCount = r;
  }
}
const b = new n(d, void 0, e => {
  e.item = null, e.target = null, e.defaultPrevented = !1, e.cancellable = !1;
});
function v(e) {
  e && "object" == typeof e && "destroy" in e && "function" == typeof e.destroy && e.destroy();
}
function y(e) {
  return e ? e instanceof R ? e.toArray() : e.length ? Array.prototype.slice.apply(e) : [] : [];
}
function E(e) {
  if (e?.length) return e[0];
}
function A(e, t, s, i) {
  const r = Math.min(e.length - s, t.length - i);
  let n = 0;
  for (; n < r && e[s + n] === t[i + n];) n++;
  return n;
}
function C(e, t, s, i) {
  t && t.forEach((t, r, n) => {
    e.push(t), C(e, s.call(i, t, r, n), s, i);
  });
}
const O = new Set(),
  M = new Set(),
  D = new Set(),
  x = new Map();
let j = 0,
  R = g = class extends s.EventedAccessor {
    static isCollection(e) {
      return null != e && e instanceof g;
    }
    constructor(e) {
      super(e), this._chgListeners = [], this._notifications = null, this._updating = !1, this._timer = null, this._observable = new u(), this.length = 0, this._items = [], Object.defineProperty(this, "uid", {
        value: j++
      });
    }
    normalizeCtorArgs(e) {
      return e ? Array.isArray(e) || e instanceof g ? {
        items: e
      } : e : {};
    }
    destroy() {
      this._removeAllRaw(), this._timer && this._timer.remove(), this._emitter.destroy(), this._notifications = null;
    }
    *[Symbol.iterator]() {
      yield* this.items;
    }
    get items() {
      return c(this._observable), this._items;
    }
    set items(e) {
      this._emitBeforeChanges(h.ADD) || (this._splice(0, this.length, y(e)), this._emitAfterChanges(h.ADD));
    }
    hasEventListener(e) {
      return !this.destroyed && ("change" === e ? this._chgListeners.length > 0 : this._emitter.hasEventListener(e));
    }
    on(e, t) {
      if (this.destroyed) return i();
      if ("change" === e) {
        const e = this._chgListeners,
          s = {
            removed: !1,
            callback: t
          };
        return e.push(s), this._notifications && this._notifications.push({
          listeners: e.slice(),
          items: this._items.slice(),
          changes: []
        }), i(() => {
          s.removed = !0, e.splice(e.indexOf(s), 1);
        });
      }
      return this._emitter.on(e, t);
    }
    once(e, t) {
      const s = "deref" in t ? () => t.deref() : () => t,
        i = this.on(e, e => {
          s()?.call(null, e), i.remove();
        });
      return i;
    }
    add(e, t) {
      if (c(this._observable), this._emitBeforeChanges(h.ADD)) return this;
      const s = this.getNextIndex(t ?? null);
      return this._splice(s, 0, [e]), this._emitAfterChanges(h.ADD), this;
    }
    addMany(e, t = this._items.length) {
      if (c(this._observable), !e?.length) return this;
      if (this._emitBeforeChanges(h.ADD)) return this;
      const s = this.getNextIndex(t);
      return this._splice(s, 0, y(e)), this._emitAfterChanges(h.ADD), this;
    }
    at(e) {
      if (c(this._observable), (e = Math.trunc(e) || 0) < 0 && (e += this.length), !(e < 0 || e >= this.length)) return this._items[e];
    }
    removeAll() {
      if (c(this._observable), !this.length || this._emitBeforeChanges(h.REMOVE)) return [];
      const e = this._removeAllRaw();
      return this._emitAfterChanges(h.REMOVE), e;
    }
    _removeAllRaw() {
      return 0 === this.length ? [] : this._splice(0, this.length) || [];
    }
    clone() {
      return c(this._observable), this._createNewInstance({
        items: this._items.map(r)
      });
    }
    concat(...e) {
      c(this._observable);
      const t = e.map(y);
      return this._createNewInstance({
        items: this._items.concat(...t)
      });
    }
    drain(e, t) {
      if (c(this._observable), !this.length || this._emitBeforeChanges(h.REMOVE)) return;
      const s = this._splice(0, this.length),
        i = s.length;
      for (let r = 0; r < i; r++) e.call(t, s[r], r, s);
      this._emitAfterChanges(h.REMOVE);
    }
    destroyAll() {
      this.drain(v);
    }
    destroyMany(e) {
      const t = this.removeMany(e);
      return t.forEach(v), t;
    }
    every(e, t) {
      return c(this._observable), this._items.every(e, t);
    }
    filter(e, t) {
      let s;
      return c(this._observable), s = 2 === arguments.length ? this._items.filter(e, t) : this._items.filter(e), this._createNewInstance({
        items: s
      });
    }
    find(e, t) {
      return c(this._observable), this._items.find(e, t);
    }
    findIndex(e, t) {
      return c(this._observable), this._items.findIndex(e, t);
    }
    flatten(e, t) {
      c(this._observable);
      const s = [];
      return C(s, this, e, t), new g(s);
    }
    forEach(e, t) {
      return c(this._observable), this._items.forEach(e, t);
    }
    getItemAt(e) {
      return c(this._observable), this._items[e];
    }
    getNextIndex(e) {
      c(this._observable);
      const t = this.length;
      return (e = e ?? t) < 0 ? e = 0 : e > t && (e = t), e;
    }
    includes(e, t = 0) {
      return c(this._observable), this._items.includes(e, t);
    }
    indexOf(e, t = 0) {
      return c(this._observable), this._items.indexOf(e, t);
    }
    join(e = ",") {
      return c(this._observable), this._items.join(e);
    }
    lastIndexOf(e, t = this.length - 1) {
      return c(this._observable), this._items.lastIndexOf(e, t);
    }
    map(e, t) {
      c(this._observable);
      const s = this._items.map(e, t);
      return new g({
        items: s
      });
    }
    reorder(e, t = this.length - 1) {
      c(this._observable);
      const s = this.indexOf(e);
      if (-1 !== s) {
        if (t < 0 ? t = 0 : t >= this.length && (t = this.length - 1), s !== t) {
          if (this._emitBeforeChanges(h.MOVE)) return e;
          this._splice(s, 1), this._splice(t, 0, [e]), this._emitAfterChanges(h.MOVE);
        }
        return e;
      }
    }
    pop() {
      if (c(this._observable), !this.length || this._emitBeforeChanges(h.REMOVE)) return;
      const e = E(this._splice(this.length - 1, 1));
      return this._emitAfterChanges(h.REMOVE), e;
    }
    push(...e) {
      return c(this._observable), this._emitBeforeChanges(h.ADD) || (this._splice(this.length, 0, e), this._emitAfterChanges(h.ADD)), this.length;
    }
    reduce(e, t) {
      c(this._observable);
      const s = this._items;
      return 2 === arguments.length ? s.reduce(e, t) : s.reduce(e);
    }
    reduceRight(e, t) {
      c(this._observable);
      const s = this._items;
      return 2 === arguments.length ? s.reduceRight(e, t) : s.reduceRight(e);
    }
    remove(e) {
      return c(this._observable), this.removeAt(this.indexOf(e));
    }
    removeAt(e) {
      if (c(this._observable), e < 0 || e >= this.length || this._emitBeforeChanges(h.REMOVE)) return;
      const t = E(this._splice(e, 1));
      return this._emitAfterChanges(h.REMOVE), t;
    }
    removeMany(e) {
      if (c(this._observable), !e?.length || this._emitBeforeChanges(h.REMOVE)) return [];
      const t = e instanceof g ? e.toArray() : e,
        s = this._items,
        i = [],
        r = t.length;
      for (let n = 0; n < r; n++) {
        const e = t[n],
          r = s.indexOf(e);
        if (r > -1) {
          const e = 1 + A(t, s, n + 1, r + 1),
            h = this._splice(r, e);
          h && h.length > 0 && i.push.apply(i, h), n += e - 1;
        }
      }
      return this._emitAfterChanges(h.REMOVE), i;
    }
    reverse() {
      if (c(this._observable), this._emitBeforeChanges(h.MOVE)) return this;
      const e = this._splice(0, this.length);
      return e && (e.reverse(), this._splice(0, 0, e)), this._emitAfterChanges(h.MOVE), this;
    }
    shift() {
      if (c(this._observable), !this.length || this._emitBeforeChanges(h.REMOVE)) return;
      const e = E(this._splice(0, 1));
      return this._emitAfterChanges(h.REMOVE), e;
    }
    slice(e = 0, t = this.length) {
      return c(this._observable), this._createNewInstance({
        items: this._items.slice(e, t)
      });
    }
    some(e, t) {
      return c(this._observable), this._items.some(e, t);
    }
    sort(e) {
      if (c(this._observable), !this.length || this._emitBeforeChanges(h.MOVE)) return this;
      const t = this._splice(0, this.length);
      return arguments.length ? t.sort(e) : t.sort(), this._splice(0, 0, t), this._emitAfterChanges(h.MOVE), this;
    }
    splice(e, t, ...s) {
      c(this._observable);
      const i = (t ? h.REMOVE : 0) | (s.length ? h.ADD : 0);
      if (this._emitBeforeChanges(i)) return [];
      const r = this._splice(e, t, s) || [];
      return this._emitAfterChanges(i), r;
    }
    toArray() {
      return c(this._observable), this._items.slice();
    }
    toJSON() {
      return c(this._observable), this.toArray();
    }
    toLocaleString() {
      return c(this._observable), this._items.toLocaleString();
    }
    toString() {
      return c(this._observable), this._items.toString();
    }
    unshift(...e) {
      return c(this._observable), !e.length || this._emitBeforeChanges(h.ADD) || (this._splice(0, 0, e), this._emitAfterChanges(h.ADD)), this.length;
    }
    _createNewInstance(e) {
      return new this.constructor(e);
    }
    _splice(e, t, s) {
      const i = this._items,
        r = this.itemType;
      let n, h;
      if (!this._notifications && this.hasEventListener("change") && (this._notifications = [{
        listeners: this._chgListeners.slice(),
        items: this._items.slice(),
        changes: []
      }], this._timer && this._timer.remove(), this._updating = !0, this._timer = o(() => this._dispatchChange())), e < 0 && (e += this.length), t) {
        if (h = i.splice(e, t), this.hasEventListener("before-remove")) {
          const t = b.acquire();
          t.target = this, t.cancellable = !0;
          for (let s = 0, r = h.length; s < r; s++) n = h[s], t.reset(n), this.emit("before-remove", t), t.defaultPrevented && (h.splice(s, 1), i.splice(e, 0, n), e += 1, s -= 1, r -= 1);
          b.release(t);
        }
        if (this.length = this._items.length, this.hasEventListener("after-remove")) {
          const e = b.acquire();
          e.target = this, e.cancellable = !1;
          const t = h.length;
          for (let s = 0; s < t; s++) e.reset(h[s]), this.emit("after-remove", e);
          b.release(e);
        }
      }
      if (s?.length) {
        if (r) {
          const e = [];
          for (const t of s) {
            const s = r.ensureType(t);
            null == s && null != t || e.push(s);
          }
          s = e;
        }
        const t = this.hasEventListener("before-add"),
          n = this.hasEventListener("after-add"),
          h = e === this.length;
        if (t || n) {
          const r = b.acquire();
          r.target = this, r.cancellable = !0;
          const o = b.acquire();
          o.target = this, o.cancellable = !1;
          for (const l of s) t ? (r.reset(l), this.emit("before-add", r), r.defaultPrevented || (h ? i.push(l) : i.splice(e++, 0, l), this._set("length", i.length), n && (o.reset(l), this.emit("after-add", o)))) : (h ? i.push(l) : i.splice(e++, 0, l), this._set("length", i.length), o.reset(l), this.emit("after-add", o));
          b.release(o), b.release(r);
        } else {
          if (h) for (const e of s) i.push(e);else i.splice(e, 0, ...s);
          this._set("length", i.length);
        }
      }
      if ((s?.length || h?.length) && this._notifyChangeEvent(s, h), this.hasEventListener("after-splice")) {
        const i = new p(this, s, h, e, t);
        this.emit("after-splice", i);
      }
      return h;
    }
    _emitBeforeChanges(e) {
      let t = !1;
      if (this.hasEventListener("before-changes")) {
        const s = b.acquire();
        s.target = this, s.cancellable = !0, s.type = e, this.emit("before-changes", s), t = s.defaultPrevented, b.release(s);
      }
      return t;
    }
    _emitAfterChanges(e) {
      if (this.hasEventListener("after-changes")) {
        const t = b.acquire();
        t.target = this, t.cancellable = !1, t.type = e, this.emit("after-changes", t), b.release(t);
      }
      this._observable.notify();
    }
    _notifyChangeEvent(e, t) {
      this.hasEventListener("change") && this._notifications && this._notifications[this._notifications.length - 1].changes.push({
        added: e,
        removed: t
      });
    }
    get updating() {
      return this._updating;
    }
    _dispatchChange() {
      if (this._timer && (this._timer.remove(), this._timer = null), this._updating = !1, !this._notifications) return;
      const e = this._notifications;
      this._notifications = null;
      for (const s of e) {
        const e = s.changes;
        O.clear(), M.clear(), D.clear();
        for (const {
          added: t,
          removed: s
        } of e) {
          if (t) if (0 === D.size && 0 === M.size) for (const e of t) O.add(e);else for (const e of t) M.has(e) ? (D.add(e), M.delete(e)) : D.has(e) || O.add(e);
          if (s) if (0 === D.size && 0 === O.size) for (const e of s) M.add(e);else for (const e of s) O.has(e) ? O.delete(e) : (D.delete(e), M.add(e));
        }
        const i = t.acquire();
        O.forEach(e => {
          i.push(e);
        });
        const r = t.acquire();
        M.forEach(e => {
          r.push(e);
        });
        const n = this._items,
          h = s.items,
          o = t.acquire();
        if (D.forEach(e => {
          h.indexOf(e) !== n.indexOf(e) && o.push(e);
        }), s.listeners && (i.length || r.length || o.length)) {
          const e = {
              target: this,
              added: i,
              removed: r,
              moved: o
            },
            t = s.listeners.length;
          for (let i = 0; i < t; i++) {
            const t = s.listeners[i];
            t.removed || t.callback.call(this, e);
          }
        }
        t.release(i), t.release(r), t.release(o);
      }
      O.clear(), M.clear(), D.clear();
    }
  };
R.ofType = t => {
  if (!t) return g;
  if (x.has(t)) return x.get(t);
  let s = null;
  if ("function" == typeof t) s = t.prototype.declaredClass;else if (t.base) s = t.base.prototype.declaredClass;else for (const e in t.typeMap) {
    const i = t.typeMap[e].prototype.declaredClass;
    s ? s += ` | ${i}` : s = i;
  }
  let i = class extends g {};
  return e([_({
    Type: t,
    ensureType: "function" == typeof t ? l(t) : a(t)
  })], i.prototype, "itemType", void 0), i = e([m(`esri.core.Collection<${s}>`)], i), x.set(t, i), i;
}, e([f()], R.prototype, "_updating", void 0), e([f()], R.prototype, "length", void 0), e([f()], R.prototype, "items", null), e([f({
  readOnly: !0
})], R.prototype, "updating", null), R = g = e([m("esri.core.Collection")], R);
const V = R;
export { V as default };